import { useState, useLayoutEffect } from "react";

function getSize(el: HTMLElement) {
  if (!el) {
    return {
      width: 0,
      height: 0,
    };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

export function useComponentSize(ref: React.MutableRefObject<any>) {
  const [componentSize, setComponentSize] = useState(
    getSize(ref ? ref.current : {})
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current));
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    ref.current.addEventListener("load", handleResize);

    return function () {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref.current]);

  return componentSize;
}
