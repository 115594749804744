import React, { useState, useEffect } from "react";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";
import { css } from "styled-components";
import { ProductPreview } from "../ProductPreview";
import { Rnd } from "react-rnd";
import { InteriorDesignerItem } from "../../types/items/InteriorDesignerItem";
import { calculateOrderItem } from "../../helpers/product";
import { Row, Spacing } from "../../helpers/layout";
import RemoveIcon from "../../icons/RemoveIcon";
import { deleteInteriorDesignerItem } from "../../actions/deleteInteriorDesignerItem";
import { updateInteriorDesignerItem } from "../../actions/updateInteriorDesignerItem";
import PensilIcon from "../../icons/PencilIcon";
import { PlainButton } from "../PlainButton";
import { Price } from "../Price";
import PathIcon from "../../icons/PathIcon";
import { Colors } from "../../branding";
import { useCommonData } from "../../context/CommonDataContext";
import { getCustomSize } from "../../helpers/product";
import { useDebounce } from "use-debounce";

type CanvasSize = {
  width: number;
  height: number;
};

function sanitizeNumber(number: number) {
  return parseFloat(number.toFixed(4));
}

function safePosition(position: number, size: number, max: number) {
  if (position < 0) return 0;

  if (position > max - size) return max - size;

  return position;
}

export function CanvasItem(props: {
  item: InteriorDesignerItem;
  canvasSize: CanvasSize;
  onEdit?: (item: InteriorDesignerItem) => void;
  disable?: boolean;
  pxPerInch: number | null;
  canvasWidth: number;
  wallDimensionsSpecified: boolean;
}) {
  const { interiorDesigner, fetchInteriorDesigner } = useInteriorDesigner();
  const { canvasSize, wallDimensionsSpecified } = props;

  const [item, setItem] = useState(props.item);
  const [debouncedItem] = useDebounce(item, 300);
  const { settings } = useCommonData();
  const calculated = calculateOrderItem(item, settings);
  const relativeRatio = props.canvasWidth / 1000;
  const localPxPerInch = item.px_per_inch * relativeRatio;
  const pxPerInch =
    wallDimensionsSpecified && props.pxPerInch
      ? props.pxPerInch
      : localPxPerInch;
  const size = {
    width: calculated.width * pxPerInch,
    height: calculated.height * pxPerInch,
  };
  const price = (
    Number(calculated.subtotal) - Number(calculated.discount)
  ).toString();

  const fontSize = size.width < 100 ? "6px" : size.width < 140 ? "8px" : "10px";
  const instructionSize = wallDimensionsSpecified ? 230 : 180;
  const fullInstruction = size.width > instructionSize;

  useEffect(() => {
    const position_x = sanitizeNumber(debouncedItem.position_x);
    const position_y = sanitizeNumber(debouncedItem.position_y);

    const updateItem = async function () {
      await updateInteriorDesignerItem(
        interiorDesigner.uuid,
        debouncedItem.id,
        {
          product: debouncedItem.product.id,
          options: debouncedItem.options,
          size: debouncedItem.size,
          position_x,
          position_y,
          px_per_inch: debouncedItem.px_per_inch,
        }
      );
    };

    if (
      props.item.position_x !== position_x ||
      props.item.position_y !== position_y ||
      props.item.px_per_inch !== debouncedItem.px_per_inch ||
      props.item.size.width !== debouncedItem.size.width ||
      props.item.size.height !== debouncedItem.size.height
    ) {
      updateItem();
    }
  }, [
    debouncedItem.position_x,
    debouncedItem.position_y,
    debouncedItem.size,
    debouncedItem.px_per_inch,
  ]);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  return (
    <Rnd
      css={
        !props.disable
          ? css`
              position: relative;

              &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                margin: auto;
                background: ${Colors.white};
                opacity: 0;
                transition: all 200ms;
                content: "";
              }

              .actions {
                opacity: 0;
                transition: all 300ms;
              }

              &:hover {
                &:before {
                  position: absolute;
                  width: calc(100% + 30px);
                  height: calc(100% + 60px);
                  top: -30px;
                  left: -15px;
                  margin: auto;
                  background: ${Colors.white};
                  opacity: 1;
                  content: "";
                }
                .actions {
                  opacity: 1;
                }
              }
            `
          : null
      }
      disableDragging={props.disable}
      size={size}
      position={{
        x: safePosition(
          item.position_x * canvasSize.width,
          size.width,
          canvasSize.width
        ),
        y: safePosition(
          item.position_y * canvasSize.height,
          size.height,
          canvasSize.height
        ),
      }}
      onDragStop={(_e, position) => {
        setItem((item) => {
          return {
            ...item,
            position_x: sanitizeNumber(position.x / canvasSize.width),
            position_y: sanitizeNumber(position.y / canvasSize.height),
          };
        });
      }}
      lockAspectRatio
      onResizeStop={(_e, _direction, _ref) => {
        // If user didn't specify wall dimensions - we update item pxPerInch ratio
        if (!wallDimensionsSpecified) {
          const newPxPerInch =
            (_ref.offsetWidth / size.width) * item.px_per_inch;

          setItem((item) => {
            return {
              ...item,
              px_per_inch: newPxPerInch,
            };
          });

          return;
        }

        // If he/she specified - we update print size
        const deltaChange = _ref.offsetWidth / size.width;
        const sizeInch = item.size.width * deltaChange;
        const similarSize = getCustomSize(sizeInch, props.item.product.sizes);

        setItem((item) => {
          return {
            ...item,
            size: similarSize,
          };
        });
      }}
      enableResizing={{
        bottom: false,
        bottomLeft: false,
        bottomRight: props.disable ? false : true,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }}
      resizeHandleStyles={{
        bottomRight: {
          width: instructionSize,
          height: 25,
          bottom: -25,
          right: 0,
        },
      }}
      resizeHandleComponent={{
        bottomRight: (
          <div
            className="actions"
            css={css`
              position: absolute;
              right: 0;
              padding: 0 ${Spacing.xs};
              mix-blend-mode: difference;
              color: ${Colors.white};
              line-height: 25px;

              svg {
                path {
                  fill: ${Colors.white};
                }
              }
            `}
          >
            <div
              css={css`
                position: relative;
                margin-right: 1em;
                display: inline-block;
                vertical-align: middle;
                font-size: ${fontSize};
                line-height: 1;
              `}
            >
              {fullInstruction ? "Click & Drag to Resize" : "Resize"}
            </div>
            <div
              css={css`
                display: inline-block;
                vertical-align: middle;
              `}
            >
              <PathIcon />
            </div>
          </div>
        ),
      }}
    >
      {!props.disable && (
        <div
          className="actions"
          css={css`
            position: absolute;
            width: 100%;
            height: 20px;
            padding-top: 5px;
            top: -25px;
            font-size: ${fontSize};
          `}
        >
          <Row justify="space-between" align="center">
            <div>
              <PlainButton
                onClick={async () => {
                  await deleteInteriorDesignerItem(
                    interiorDesigner.uuid,
                    item.id
                  );
                  await fetchInteriorDesigner();
                }}
                icon={<RemoveIcon />}
              />
            </div>
            <div>
              <PlainButton
                onClick={() => {
                  props.onEdit && props.onEdit(item);
                }}
                icon={<PensilIcon />}
                iconPosition="right"
                hoverEffect
              >
                {size.width < 100
                  ? ""
                  : size.width < 160
                  ? "Change"
                  : "Change Options"}
              </PlainButton>
            </div>
          </Row>
        </div>
      )}
      <div
        css={css`
          position: relative;
        `}
      >
        <ProductPreview
          product={item.product}
          printSize={item.size}
          options={item.options}
          darkBackground
        />
      </div>
      {wallDimensionsSpecified && (
        <div
          className="actions"
          css={css`
            position: absolute;
            width: 100%;
            height: 25px;
            line-height: 25px;
            bottom: -25px;
            font-size: ${fontSize};
          `}
        >
          <Row align="center" gutter={Spacing.s}>
            <span>
              {calculated.width} x {calculated.height}
            </span>
            {size.width > 160 && (
              <strong
                css={css`
                  position: relative;
                  top: -1px;
                `}
              >
                <Price price={price} />
              </strong>
            )}
          </Row>
        </div>
      )}
    </Rnd>
  );
}
