import { maybeAuthorised } from "../request";
import { InteriorDesignerItem } from "../types/items/InteriorDesignerItem";

export async function updateInteriorDesignerItem(
  token: string,
  id: number,
  data: Pick<InteriorDesignerItem, "size" | "options"> & {
    product: number;
    position_x?: number;
    position_y?: number;
    px_per_inch?: number;
  }
) {
  const API = maybeAuthorised();

  return await API.request<InteriorDesignerItem>({
    method: "PUT",
    url: `/api/frontend/interior-designer/${token}/${id}/`,
    data
  });
}
