import styled, { css } from "styled-components";
import { Spacing } from "../../helpers/layout";
import { fontFamilies } from "../../typography";
import { useWrapper } from "../../helpers/useWrapper";
import { useResponsive } from "../../context/ResponsiveContext";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export function Header(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const spacing = useWrapper("header");
  const { isSmallMobile } = useResponsive();

  return (
    <div
      css={css`
        flex: 0 0 auto;
        padding: ${Spacing.m} ${spacing};
        box-sizing: border-box;
        display: flex;
        align-items: center;

        ${isSmallMobile &&
        css`
          padding: ${Spacing.s} ${spacing};
        `}
      `}
    >
      {props.children}
    </div>
  );
}

export const Body = styled.div`
  flex: 1 0 20%;
  min-height: 0;
`;

export const Footer = styled.div`
  flex: 0 0 auto;
`;

export const Ul = styled.ul`
  text-align: left;
  list-style: none;
  counter-reset: counter;
  padding-left: 0;
`;

export const Li = styled.li`
  position: relative;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: ${Spacing.l};
  counter-increment: counter;
  padding-left: 70px;

  &:last-child {
    margin-bottom: ${Spacing.none};
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 34px;
    line-height: 1;
    font-family: ${fontFamilies.freight};
    font-weight: 600;
    content: "0" counter(counter) ".";
  }
`;
