const RemoveIcon = () => (
  <svg width="13px" height="12px" viewBox="0 0 13 12" version="1.1">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g transform="translate(-6.000000, -6.000000)" stroke="#000000">
        <g transform="translate(12.500000, 12.000000) rotate(45.000000) translate(-12.500000, -12.000000) translate(4.000000, 4.000000)">
          <line x1="8.5" y1="0.5" x2="8.5" y2="15.5"></line>
          <line
            x1="8.5"
            y1="0.5"
            x2="8.5"
            y2="15.5"
            transform="translate(8.500000, 8.000000) rotate(-90.000000) translate(-8.500000, -8.000000) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);
export default RemoveIcon;
