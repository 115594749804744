const PensilIcon = () => (
  <svg width="12px" height="12px" viewBox="0 0 12 12">
    <path
      d="M0 3L3 2.16268L0.821928 0L0 3L0 3Z"
      transform="translate(0 9)"
      fill="#000000"
      stroke="none"
    />
    <path
      d="M1.68211 0.260973C1.33192 -0.086991 0.764965 -0.086991 0.414771 0.260973L0 0.673987L2.31059 3L2.73794 2.57461C3.08735 2.22588 3.08735 1.66128 2.73794 1.31255L1.68211 0.260973Z"
      transform="translate(9 0)"
      fill="#000000"
      stroke="none"
    />
    <path
      d="M2.19622 9.35398L9.23034 2.22559L7.04168 1.32928e-13L0 7.16787L2.19622 9.35398Z"
      transform="translate(1.40415 1.315643)"
      fill="#000000"
      stroke="none"
    />
  </svg>
);
export default PensilIcon;
