const PathIcon = () => (
  <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1">
    <path
      d="M0.372093 0L8 0L8 7.62791L6.88372 7.62791L6.88372 3.18937L6.88372 1.91362L5.98007 2.81728L0.797342 8L0 7.20266L5.18272 2.01993L6.08638 1.11628L4.81063 1.11628L0.372093 1.11628L0.372093 0L0.372093 0Z"
      transform="matrix(-4.371139E-08 1 -1 -4.371139E-08 8 0)"
      fill="#fff"
      stroke="none"
    />
  </svg>
);
export default PathIcon;
